
import React from 'react';
import OnlineCourses from '../allcourses/OnlineCourses';
import "./AllCourses.css"

import BackV2 from "../common/back/BackDetailV2"

const AllCourses = () => {
  return (

<>
<BackV2 title="" />
<OnlineCourses />
</>
  );
};

export default AllCourses;
